import Landing from "@/components/sharedComponents/landing/landing.component";
import { LandingItem } from "@/interfaces/landingItem";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./InventoryView.template.vue";

@Component({
  mixins: [Template],
  components: {
    Landing
  }
})
export default class InventoryViewComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public landingOptions: LandingItem[] = [
    {
      namedRoute: "products",
      translationString: "products"
    },
    {
      namedRoute: "product-category",
      translationString: "product_categories"
    },
    {
      namedRoute: "batch-types",
      translationString: "batch_types"
    },
    {
      namedRoute: "strains",
      translationString: "strain.strain"
    },
    {
      namedRoute: "brands",
      translationString: "brands"
    },
    {
      namedRoute: "vendor-list",
      translationString: "vendors_labs"
    },
    {
      namedRoute: "room-list",
      translationString: "inventory_rooms"
    },

    {
      namedRoute: "audit",
      translationString: "audits"
    }
  ];
  public goTo(route: string) {
    this.$router.push(route);
  }

  public mounted() {
    this.setPageNav({
      title: "inventory_tools"
    });
  }
}
